import React from 'react';

import { useTrackedState } from '../../lib/state';

import SubLayout from '../../components/SubLayout';
import { P } from '../../components/common/P.style';
import { Img } from '../../components/common/Img.style';
import ButtonList from '../../components/ButtonList';

import pancTop from '../../assets/img/pancreas-top.png';

const Pancreas = () => {
  const { size } = useTrackedState();

  return (
    <SubLayout topTitle="膵臓が弱い方へ">
      <Img src={pancTop} />
      <P>
        なんとなくだるい、疲れやすい、お腹や背中に痛みがある、よく下痢をする……それは膵臓からくる症状かもしれません。
        当院では、慢性膵炎などの「病気」になる手前（未病）の方々や、膵臓関連の採血だけ異常値が出ている方々のケアに特に力を入れています。
        「気のせいだ」「精神科に行け」。そんな言葉に苦しんでいる方を、生活療法や簡単な治療だけで改善に導いてきました。
        海外の方にも、当院をご利用いただいております。
        膵臓が心配な方は、ぜひ一度当院にご相談ください。
      </P>
      <ButtonList
        size={size}
        list={[
          { link: '/pancreas/examination', title: '膵臓ドック案内' },
          { link: '/pancreas/mibyo', title: '膵臓の『未病』についてのお話' },
        ]}
      />
    </SubLayout>
  );
};
export default Pancreas;
